import classes from './ContactUs.module.scss';
import { useTranslation } from 'react-i18next';

const ContactUs = () => {
    const { t } = useTranslation('contactUs');
    const mobileWidth = window.innerWidth > 800;

    return (
        <div className={classes.container}>
            <div className={classes.content}>
                <h3 className="orangeSuperLight">{t('title')}</h3>
                <p className="white">{t('paragraph')}</p>
                <a href="mailto:eagle-eng@pec.cloud ">
                    <p className="sub white">eagle-eng@pec.cloud</p>
                </a>
            </div>
            <img
                loading="lazy"
                alt="contacts"
                src={
                    mobileWidth
                        ? require('../../../assets/images/contacts_background.png')
                        : require('../../../assets/images/contacts_background_mobile.png')
                }
            />
        </div>
    );
};

export default ContactUs;
