export const Cookies = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="35.875" height="35.443" viewBox="0 0 35.875 35.443">
            <defs>
                <clipPath id="clipPath">
                    <rect
                        id="Rectangle_1803"
                        data-name="Rectangle 1803"
                        width="35.875"
                        height="35.443"
                        transform="translate(0 0)"
                        fill="none"
                    />
                </clipPath>
            </defs>
            <g id="cookies" transform="translate(0 0)">
                <g id="Group_950" data-name="Group 950" clipPath="url(#clipPath)">
                    <path
                        id="Path_1602"
                        data-name="Path 1602"
                        d="M12.625,22.944a2.75,2.75,0,1,0,2.75,2.75,2.752,2.752,0,0,0-2.75-2.75m0,4a1.25,1.25,0,1,1,1.25-1.25,1.252,1.252,0,0,1-1.25,1.25"
                        fill="#fff"
                    />
                    <path
                        id="Path_1603"
                        data-name="Path 1603"
                        d="M31.049,3.75a1.5,1.5,0,1,0-1.5-1.5,1.5,1.5,0,0,0,1.5,1.5"
                        fill="#fff"
                    />
                    <path
                        id="Path_1604"
                        data-name="Path 1604"
                        d="M34.375,10.123a1.5,1.5,0,1,0-1.5-1.5,1.5,1.5,0,0,0,1.5,1.5"
                        fill="#fff"
                    />
                    <path
                        id="Path_1605"
                        data-name="Path 1605"
                        d="M28.319,9.623a2.5,2.5,0,1,0-2.5-2.5,2.5,2.5,0,0,0,2.5,2.5"
                        fill="#fff"
                    />
                    <path
                        id="Path_1606"
                        data-name="Path 1606"
                        d="M21.261,15.808a3.75,3.75,0,1,0,3.75,3.75,3.755,3.755,0,0,0-3.75-3.75m0,6a2.25,2.25,0,1,1,2.25-2.25,2.253,2.253,0,0,1-2.25,2.25"
                        fill="#fff"
                    />
                    <path
                        id="Path_1607"
                        data-name="Path 1607"
                        d="M34.71,17.87a4.237,4.237,0,0,1-4.137-3.533.749.749,0,0,0-.977-.586A6.248,6.248,0,0,1,21.644,5.98a.75.75,0,0,0-.613-.965A4.247,4.247,0,0,1,17.36.9,1.281,1.281,0,0,0,17.373.75.752.752,0,0,0,17.136.2a.789.789,0,0,0-.562-.2,17.74,17.74,0,1,0,18.878,18.66.75.75,0,0,0-.742-.791M17.75,33.943A16.24,16.24,0,0,1,15.914,1.566a5.774,5.774,0,0,0,4.1,4.761,7.675,7.675,0,0,0-.147,1.5,7.759,7.759,0,0,0,7.75,7.75,7.646,7.646,0,0,0,1.676-.186A5.735,5.735,0,0,0,33.9,19.307,16.234,16.234,0,0,1,17.75,33.943"
                        fill="#fff"
                    />
                    <path
                        id="Path_1608"
                        data-name="Path 1608"
                        d="M13.172,13.431a2.75,2.75,0,1,0-2.75,2.75,2.752,2.752,0,0,0,2.75-2.75m-4,0a1.25,1.25,0,1,1,1.25,1.25,1.252,1.252,0,0,1-1.25-1.25"
                        fill="#fff"
                    />
                </g>
            </g>
        </svg>
    );
};
