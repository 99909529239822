import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import classes from './AboutUsCarousel.module.scss';
import { useTranslation } from 'react-i18next';
import { LeftArrow, RightArrow } from 'components/icons';

const AboutUsCarousel = () => {
    const { t } = useTranslation('carousel');

    return (
        <div className={classes.container}>
            <Carousel
                showStatus={false}
                showIndicators={true}
                showThumbs={false}
                showArrows={true}
                swipeable={false}
                renderArrowPrev={(clickHandler, hasPrev) => {
                    return (
                        <div
                            tabIndex={0}
                            className={`${classes.leftArrow}  ${hasPrev ? '' : classes.invisible}`}
                            onClick={clickHandler}
                        >
                            <LeftArrow />
                        </div>
                    );
                }}
                renderArrowNext={(clickHandler, hasNext) => {
                    return (
                        <div
                            tabIndex={0}
                            className={`${classes.rightArrow} ${hasNext ? '' : classes.invisible}`}
                            onClick={clickHandler}
                        >
                            <RightArrow />
                        </div>
                    );
                }}
            >
                <div className={classes.info}>
                    <img loading="lazy" src={require('../../../../assets/images/Ferretti.png')} alt="Ferretti" />
                    <p className={`white ${classes.explication}`}>
                        <span className={classes.person}>
                            <span className="sub">LUCA FERRETTI</span>
                            <b>{t('ferretti.role')}</b>
                        </span>
                        <span>{t('ferretti.firstP')}</span>
                        <span>{t('ferretti.secondP')}</span>
                    </p>
                </div>
                <div className={classes.info}>
                    <img loading="lazy" src={require('../../../../assets/images/Antonini.png')} alt="Antonini" />
                    <p className={`white ${classes.explication}`}>
                        <span className={classes.person}>
                            <span className="sub">FABIO ANTONINI</span>
                            <b>{t('antonini.role')}</b>
                        </span>
                        <span>{t('antonini.firstP')}</span>
                        <span>{t('antonini.secondP')}</span>
                    </p>
                </div>
                <div className={classes.info}>
                    <img loading="lazy" src={require('../../../../assets/images/Rosati.png')} alt="Rosati" />
                    <p className={`white ${classes.explication}`}>
                        <span className={classes.person}>
                            <span className="sub">GIOVACCHINO ROSATI</span>
                            <b>CEO</b>
                        </span>
                        <span>{t('rosati.firstP')}</span>
                        <span>{t('rosati.secondP')}</span>
                        <span>{t('rosati.thirdP')}</span>
                    </p>
                </div>
            </Carousel>
        </div>
    );
};

export default AboutUsCarousel;
