import { ClickAwayListener, Tooltip } from '@mui/material';
import classes from './LangMenu.module.scss';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const LangMenu = () => {
  const { t, i18n } = useTranslation('translation');
  const [open, setOpen] = useState(false);
  const onChangeLanguage = useCallback(
    (language: string) => i18n.changeLanguage(language),
    [i18n]
  );
  const handleTooltipClose = () => setOpen(false);
  const handleTooltipOpen = () => setOpen(prev => !prev);
  const navigate = useNavigate();
  const path = document.location.pathname.concat('');
  const loc = path.slice(0, -2);

  const langListElement = () => {
    return (
      <div className={classes.langButtonContainer}>
        <div>
          <img src={require('../../assets/images/ita.png')} alt="flag_ita" />
          <input
            type="radio"
            id="ita"
            name="lang"
            value="it"
            onClick={() => {
              onChangeLanguage('it');
              navigate(`${loc}it`);
            }}
          />
          <label
            htmlFor="ita"
            className={i18n.language.includes('it') ? classes.blue : ''}
          >
            <p>{t('it')}</p>
          </label>
        </div>
        <div>
          <img src={require('../../assets/images/eng.png')} alt="flag_eng" />
          <input
            type="radio"
            id="eng"
            name="lang"
            value="en"
            onClick={() => {
              onChangeLanguage('en');
              navigate(`${loc}en`);
            }}
          />
          <label
            htmlFor="eng"
            className={i18n.language.includes('en') ? classes.blue : ''}
          >
            <p>{t('en')}</p>
          </label>
        </div>
      </div>
    );
  };

  return (
    <div>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <Tooltip
            slotProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'white',
                  border: '1px solid #96acab',
                  borderRadius: '2px',
                  boxShadow: '5px 5px 20px  grey'
                }
              },
              arrow: {
                sx: {
                  color: 'white',
                  '&::before': {
                    border: '1px solid #96acab',
                    boxSizing: 'border-box'
                  }
                }
              }
            }}
            PopperProps={{
              disablePortal: true
            }}
            arrow
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={langListElement()}
          >
            <button className={classes.button} onClick={handleTooltipOpen}>
              <img
                className={classes.flag}
                src={
                  i18n.language.includes('it')
                    ? require('../../assets/images/ita.png')
                    : require('../../assets/images/eng.png')
                }
                alt="lang_flag"
              />
              <p>{i18n.language.includes('it') ? 'Italiano' : 'English'}</p>
            </button>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default LangMenu;
