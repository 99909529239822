import { FC } from 'react';
import classes from './CertificationItem.module.scss';
import { useTranslation } from 'react-i18next';

type CertificationItemProps = FC<{
    title: string;
}>;

const CertificationItem: CertificationItemProps = ({ title }) => {
    const { t } = useTranslation('certifications');

    return (
        <div className={classes.itemContainer}>
            <p className="upper primaryDark">{t(('list.' + title) as any)}</p>
            <p className="lower grayMedium">{t(('list.' + title + 'P') as any)}</p>
        </div>
    );
};

export default CertificationItem;
