import ServiceCard from 'components/serviceCard/ServiceCard';
import classes from './Services.module.scss';
import { EditDocument, Engineering, Flood, Handshake, HomeWork, LocalFireDepartment, QueryStats, Study } from 'components/icons';
import { useTranslation } from 'react-i18next';

const Services = () => {
    const { t } = useTranslation('services');

    return (
        <div className={classes.container}>
            <h3 className="grayMediumDark">{t('title')}</h3>
            <div className={classes.cardGroups}>
                <ServiceCard svg={<Study />} title={t('feasibility')} />
                <ServiceCard svg={<Handshake />} title={t('consultancy')} />
                <ServiceCard svg={<QueryStats />} title={t('investigation')} />
                <ServiceCard svg={<Flood />} title={t('seismic')} />
                <ServiceCard svg={<HomeWork />} title={t('civil')} />
                <ServiceCard svg={<EditDocument />} title={t('site')} />
                <ServiceCard svg={<Engineering />} title={t('structural')} />
                <ServiceCard svg={<LocalFireDepartment />} title={t('fire')} />
            </div>
        </div>
    );
};

export default Services;
