export const ArrowDownward = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_307_457)">
                <path d="M9.5275 11L14.5 15.9617L19.4725 11L21 12.5275L14.5 19.0275L8 12.5275L9.5275 11Z" fill="#DD4F17" />
            </g>
            <defs>
                <clipPath id="clip0_307_457">
                    <rect width="30" height="30" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
