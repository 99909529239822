export const LocalFireDepartment = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11 28C11 30.1 11.475 32.075 12.425 33.925C13.375 35.775 14.7 37.3167 16.4 38.55C16.2667 38.15 16.1667 37.7417 16.1 37.325C16.0333 36.9083 16 36.5 16 36.1C16 35.0333 16.2 34.0333 16.6 33.1C17 32.1667 17.5833 31.3167 18.35 30.55L24 25L29.65 30.55C30.4167 31.3167 31 32.1667 31.4 33.1C31.8 34.0333 32 35.0333 32 36.1C32 36.5 31.9667 36.9083 31.9 37.325C31.8333 37.7417 31.7333 38.15 31.6 38.55C33.3 37.3167 34.625 35.775 35.575 33.925C36.525 32.075 37 30.1 37 28C37 26.2 36.6167 24.4417 35.85 22.725C35.0833 21.0083 33.9833 19.4333 32.55 18C31.85 18.5 31.1167 18.8917 30.35 19.175C29.5833 19.4583 28.8167 19.6 28.05 19.6C26.0167 19.6 24.3333 18.9083 23 17.525C21.6667 16.1417 21 14.4 21 12.3V11.3C19.4667 12.4 18.0833 13.6167 16.85 14.95C15.6167 16.2833 14.5667 17.675 13.7 19.125C12.8333 20.575 12.1667 22.0583 11.7 23.575C11.2333 25.0917 11 26.5667 11 28ZM24 29.2L20.45 32.7C19.9833 33.1667 19.625 33.6833 19.375 34.25C19.125 34.8167 19 35.4333 19 36.1C19 37.4667 19.4833 38.625 20.45 39.575C21.4167 40.525 22.6 41 24 41C25.4 41 26.5833 40.525 27.55 39.575C28.5167 38.625 29 37.4667 29 36.1C29 35.4333 28.875 34.8167 28.625 34.25C28.375 33.6833 28.0167 33.1667 27.55 32.7L24 29.2ZM24 6V12.6C24 13.7333 24.3917 14.6833 25.175 15.45C25.9583 16.2167 26.9167 16.6 28.05 16.6C28.65 16.6 29.2083 16.475 29.725 16.225C30.2417 15.975 30.7 15.6 31.1 15.1L32 14C34.4667 15.4 36.4167 17.35 37.85 19.85C39.2833 22.35 40 25.0667 40 28C40 32.4667 38.45 36.25 35.35 39.35C32.25 42.45 28.4667 44 24 44C19.5333 44 15.75 42.45 12.65 39.35C9.55 36.25 8 32.4667 8 28C8 23.7333 9.43333 19.625 12.3 15.675C15.1667 11.725 19.0667 8.5 24 6Z"
                fill="#2B3939"
            />
        </svg>
    );
};
