export const EditDocument = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11 44C10.2 44 9.5 43.7 8.9 43.1C8.3 42.5 8 41.8 8 41V7C8 6.2 8.3 5.5 8.9 4.9C9.5 4.3 10.2 4 11 4H28L40 16V23.8H37V18H26V7H11V41H26V44H11ZM37.8 29.85L39.2 31.25L31 39.45V42H33.55L41.75 33.8L43.15 35.2L34.35 44H29V38.65L37.8 29.85ZM43.15 35.2L37.8 29.85L40.85 26.8C41.15 26.5 41.5 26.35 41.9 26.35C42.3 26.35 42.65 26.5 42.95 26.8L46.2 30.05C46.5 30.35 46.65 30.7 46.65 31.1C46.65 31.5 46.5 31.85 46.2 32.15L43.15 35.2Z"
                fill="#2B3939"
            />
        </svg>
    );
};
