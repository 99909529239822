import { FC } from 'react';

type SvgIconProps = FC<{ fill?: string }>;

export const RightArrow: SvgIconProps = ({ fill }) => {
    return (
        <svg width="48" height="48" viewBox="0 0 26 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.78969 0L0 1.72216L22.9404 23.7969L0.25808 45.6233L2.04608 47.3455L25.6292 24.662C25.7467 24.549 25.84 24.4147 25.9036 24.267C25.9673 24.1193 26 23.9609 26 23.801C26 23.641 25.9673 23.4827 25.9036 23.3349C25.84 23.1872 25.7467 23.0529 25.6292 22.9399L1.78969 0Z"
                fill={fill}
            />
        </svg>
    );
};
