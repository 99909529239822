import { FC } from 'react';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';

type NavLinksProps = FC<{
  activeCustomClasses: string;
  customClasses: string;
  activeSection: string;
}>;

const NavLinks: NavLinksProps = ({
  activeSection,
  activeCustomClasses,
  customClasses
}) => {
  const { t } = useTranslation('navbar');

  return (
    <>
      <div
        className={`${customClasses}  ${
          activeSection === '#homePage' && activeCustomClasses
        } `}
      >
        <HashLink smooth to="#homePage">
          HOME
        </HashLink>
      </div>
      <div
        className={`${customClasses} ${
          activeSection === '#ourWork' && activeCustomClasses
        } `}
      >
        <HashLink smooth to="#ourWork">
          {t('whatWeDo')}
        </HashLink>
      </div>
      <div
        className={`${customClasses} ${
          activeSection === '#services' && activeCustomClasses
        } `}
      >
        <HashLink smooth to="#services">
          {t('services')}
        </HashLink>
      </div>
      <div
        className={`${customClasses}  ${
          activeSection === '#aboutUs' && activeCustomClasses
        } `}
      >
        <HashLink smooth to="#aboutUs">
          {t('AboutUs')}
        </HashLink>
      </div>
      <div
        className={`${customClasses}  ${
          activeSection === '#contacts' && activeCustomClasses
        } `}
      >
        <HashLink smooth to="#contacts">
          {t('contactUs')}
        </HashLink>
      </div>
    </>
  );
};

export default NavLinks;
