import { FC } from 'react';

type SvgIconProps = FC<{ fill?: string }>;

export const LeftArrow: SvgIconProps = ({ fill }) => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M35.2103 1L37 2.72216L14.0596 24.7969L36.7419 46.6233L34.9539 48.3455L11.3708 25.662C11.2533 25.549 11.16 25.4147 11.0964 25.267C11.0327 25.1193 11 24.9609 11 24.801C11 24.641 11.0327 24.4827 11.0964 24.3349C11.16 24.1872 11.2533 24.0529 11.3708 23.9399L35.2103 1Z"
                fill={fill}
            />
        </svg>
    );
};
