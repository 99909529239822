import { ArrowDownward } from 'components/icons';
import classes from './Certifications.module.scss';
import { useState } from 'react';
import CertificationsList from './certificationsList/CertificationList';
import { useTranslation } from 'react-i18next';

const Certifications = () => {
    const [isCertificationsListOpen, setIsCertificationsListOpen] = useState(false);
    const { t } = useTranslation('certifications');

    return (
        <div className={classes.container}>
            <h3 className="grayMediumDark">{t('title')}</h3>
            <div className={classes.paragraph}>
                <p className="grayMedium">{t('firstP')}</p>
                <p className="grayMedium">{t('secondP')}</p>
            </div>
            <div className={classes.explore}>
                <div className={classes.title} onClick={() => setIsCertificationsListOpen(!isCertificationsListOpen)}>
                    <p className="sub orangeDark">{isCertificationsListOpen ? t('readLess') : t('readMore')}</p>
                    <span className={isCertificationsListOpen ? classes.rotate : ''}>
                        <ArrowDownward />
                    </span>
                </div>
                <CertificationsList isCertificationsListOpen={isCertificationsListOpen} />
            </div>
        </div>
    );
};

export default Certifications;
