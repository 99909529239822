import { FC } from 'react';
import classes from './ClientCard.module.scss';

type ClientCardProps = FC<{
    clientLogo: string;
}>;

const ClientCard: ClientCardProps = ({ clientLogo }) => {
    return (
        <div className={classes.container}>
            <img loading="lazy" alt="logo" src={require('../../assets/images' + clientLogo)} />
        </div>
    );
};

export default ClientCard;
