import { Link } from 'react-router-dom';
import classes from './Footer.module.scss';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t, i18n } = useTranslation('footer');

  return (
    <div className={classes.container}>
      <div className={classes.grayLight}>
        <div className={classes.infoList}>
          <h5 className="orangeLight">EagleENG S.r.l.</h5>
          <span>
            <b className="white">
              Strada San Galigano, 12A, 06125 Perugia(PG), Italia
            </b>
          </span>
          <span>
            <b className="white">{t('phone')}&nbsp;075.7750322</b>
          </span>
          <span className={classes.emailContainer}>
            <span>
              <b className="white">E-mail:</b>
            </span>
            <span className={classes.email}>
              <b className="white">&nbsp;lferretti@eagle-eng.it</b>
              <b className="white">&nbsp;fantonini@eagle-eng.it</b>
            </span>
          </span>
          <span className="white">
            <b>{t('pec')}</b>
            <b>&nbsp;eagle-eng@pec.cloud</b>
          </span>
        </div>
      </div>
      <div className={classes.grayDark}>
        <div>
          <Link to={`/disclaimer/${i18n.language}`}>Disclaimer</Link>
          <Link to={`/privacy/${i18n.language}`}>Privacy Policy</Link>
        </div>
        <span>Copyright © 2015 - All Rights Reserved</span>
      </div>
    </div>
  );
};

export default Footer;
