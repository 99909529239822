export const Flood = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4 44V41C5.13333 40.9 6.04167 40.5417 6.725 39.925C7.40833 39.3083 8.63333 39 10.4 39C12.1667 39 13.425 39.3333 14.175 40C14.925 40.6667 15.9333 41 17.2 41C18.4667 41 19.475 40.6667 20.225 40C20.975 39.3333 22.2333 39 24 39C25.7667 39 27.0333 39.3333 27.8 40C28.5667 40.6667 29.5667 41 30.8 41C32.0667 41 33.075 40.5833 33.825 39.75C34.575 38.9167 35.8333 38.5 37.6 38.5C39.3667 38.5 40.5917 38.8917 41.275 39.675C41.9583 40.4583 42.8667 40.9 44 41V44C42.6 44 41.4583 43.5833 40.575 42.75C39.6917 41.9167 38.7 41.5 37.6 41.5C36.5 41.5 35.5333 41.9167 34.7 42.75C33.8667 43.5833 32.5667 44 30.8 44C29.0333 44 27.7333 43.6667 26.9 43C26.0667 42.3333 25.1 42 24 42C22.9 42 21.9333 42.3333 21.1 43C20.2667 43.6667 18.9667 44 17.2 44C15.4333 44 14.1333 43.6667 13.3 43C12.4667 42.3333 11.5 42 10.4 42C9.3 42 8.30833 42.3333 7.425 43C6.54167 43.6667 5.4 44 4 44ZM17.2 34.5C15.4333 34.5 14.1333 34.1667 13.3 33.5C12.4667 32.8333 11.5 32.5 10.4 32.5C9.3 32.5 8.30833 32.8333 7.425 33.5C6.54167 34.1667 5.4 34.5 4 34.5V31.5C5.13333 31.4 6.04167 31.0417 6.725 30.425C7.40833 29.8083 8.63333 29.5 10.4 29.5C10.7667 29.5 11.1417 29.525 11.525 29.575C11.9083 29.625 12.25 29.7 12.55 29.8L10 20.55L6.9 24.4L4.55 22.5L19.55 4L41.8 12.5L40.7 15.3L36.1 13.55L40.4 29.6C41 29.9333 41.6083 30.3333 42.225 30.8C42.8417 31.2667 43.4333 31.4833 44 31.45V34.5C42.6 34.5 41.4583 34.0833 40.575 33.25C39.6917 32.4167 38.7 32 37.6 32C36.5 32 35.5333 32.4167 34.7 33.25C33.8667 34.0833 32.5667 34.5 30.8 34.5C29.0333 34.5 27.7333 34.1667 26.9 33.5C26.0667 32.8333 25.1 32.5 24 32.5C22.9 32.5 21.9333 32.8333 21.1 33.5C20.2667 34.1667 18.9667 34.5 17.2 34.5ZM17.2 31.5C18.2333 31.5 19.1583 31.2083 19.975 30.625C20.7917 30.0417 21.7333 29.6833 22.8 29.55L20.95 22.75L27.75 20.95L30.5 31.5C31.7667 31.6333 32.8083 31.2667 33.625 30.4C34.4417 29.5333 35.6 29.0667 37.1 29L32.6 12.2L20.55 7.55L12.35 17.65L16.05 31.35C16.2833 31.4167 16.4833 31.4583 16.65 31.475C16.8167 31.4917 17 31.5 17.2 31.5Z"
                fill="#2B3939"
            />
        </svg>
    );
};
