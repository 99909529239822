import Routing from './Routing';
import React from 'react';
import './styles/main.scss';
import CookiesBanner from 'components/cookiesBanner/CookiesBanner';

function App() {
    return (
        <>
            <Routing />
            <CookiesBanner />
        </>
    );
}

export default App;

