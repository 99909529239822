import { Separator } from 'components/icons';
import { FC } from 'react';
import classes from './ServiceCard.module.scss';

type ServiceCardProps = FC<{
    svg: JSX.Element;
    title: string;
}>;

const ServiceCard: ServiceCardProps = ({ svg, title }) => {
    return (
        <div className={classes.container}>
            {svg}
            <span className={classes.title}>{title}</span>
            <span className={classes.separator}>
                <Separator />
            </span>
        </div>
    );
};

export default ServiceCard;
