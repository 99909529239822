import { FC } from 'react';
import classes from './PresentationComponent.module.scss';

type PresentationComponentProps = FC<{
    title: string;
    body: any;
}>;

const PresentationComponent: PresentationComponentProps = ({ body, title }) => {
    return (
        <>
            <h3 className="grayMediumDark">{title}</h3>
            <div className={classes.pContainer}>{body}</div>
        </>
    );
};

export default PresentationComponent;
