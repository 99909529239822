export const Study = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M37.5504 18.2L29.8004 10.45L33.3004 6.95001C33.8671 6.38334 34.5671 6.10001 35.4004 6.10001C36.2337 6.10001 36.9337 6.38334 37.5004 6.95001L41.0504 10.5C41.6171 11.0667 41.9004 11.7667 41.9004 12.6C41.9004 13.4333 41.6171 14.1333 41.0504 14.7L37.5504 18.2ZM6.00039 42V34.2L15.7004 24.5L4.65039 13.4L13.3504 4.60001L24.5004 15.75L29.8004 10.45L37.5504 18.2L32.2504 23.5L43.4004 34.65L34.6504 43.3L23.5504 32.25L13.8004 42H6.00039ZM17.8504 22.35L22.3504 17.85L18.7004 14.2L16.3004 16.6L14.2004 14.5L16.6004 12.1L13.4004 8.90001L8.90039 13.4L17.8504 22.35ZM34.5504 39.1L39.0504 34.6L35.8504 31.4L33.4504 33.8L31.3504 31.7L33.7504 29.3L30.1004 25.65L25.6004 30.15L34.5504 39.1ZM9.00039 39H12.5004L33.2504 18.25L29.7504 14.75L9.00039 35.5V39Z"
                fill="#2B3939"
            />
        </svg>
    );
};
