import { FC, useEffect, useState } from 'react';
import classes from './CookiesBanner.module.scss';
import { Cookies } from 'components/icons';
import { useTranslation } from 'react-i18next';

const CookiesBanner: FC = () => {
    const [isVisible, setIsVisible] = useState(true);
    const { t } = useTranslation('cookie');

    useEffect(() => {
        const bannerDisplayed = localStorage.getItem('cookieBannerDisplayed');
        if (bannerDisplayed) {
            setIsVisible(false);
        }
    }, []);

    const handleClose = () => {
        setIsVisible(false);
        localStorage.setItem('cookieBannerDisplayed', 'true');
    };

    return (
        <>
            {isVisible && (
                <div className={classes.container}>
                    <Cookies />
                    <div>
                        <span className="white">{t('banner_1')}</span>
                        <span className="white">{t('banner_2')}</span>
                    </div>
                    <div className={classes.accept}>
                        <button onClick={handleClose}>OK</button>
                    </div>
                </div>
            )}
        </>
    );
};

export default CookiesBanner;
