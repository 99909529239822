import ClientCard from 'components/clientCard/ClientCard';
import classes from './OurClients.module.scss';
import { useTranslation } from 'react-i18next';

const OurClients = () => {
    const { t } = useTranslation('ourClients');

    const clientLogos = [
        '/eagleprojects.png',
        '/essential_ark.png',
        '/fg.png',
        '/studio_fattori.png',
        '/inwit.png',
        '/enel.png',
        '/fastweb.png',
        '/vodafone.png',
        '/enelx.png',
        '/fibraweb.png'
    ];

    return (
        <div className={classes.container}>
            <h3 className="grayMediumDark">{t('title')}</h3>
            <div className={classes.cardContainer}>
                {clientLogos.map(logo => (
                    <ClientCard key={logo} clientLogo={logo} />
                ))}
            </div>
        </div>
    );
};

export default OurClients;
