import { ArrowBackward } from 'components/icons';
import classes from './Disclaimer.module.scss';
import Footer from 'views/home/footer/Footer';
import { useTranslation } from 'react-i18next';

const Disclaimer = () => {
    const { t } = useTranslation('disclaimer');
    return (
        <div className={classes.bodyContainer}>
            <div className={classes.disclaimerContainer}>
                <div className={classes.content}>
                    <h1>
                        <ArrowBackward />
                        DISCLAIMER
                    </h1>
                    <div className={classes.subtitle}>
                        <h5>{t('notice')}</h5>
                    </div>
                    <p>{t('surveys')}</p>
                    <p>{t('notice_1')}</p>
                    <p>{t('notice_2')}</p>
                    <p>{t('notice_3')}</p>
                    <p>{t('notice_4')}</p>
                    <p>{t('notice_5')}</p>
                    <p>{t('notice_6')}</p>
                    <p>{t('notice_7')}</p>
                    <p>{t('notice_8')}</p>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Disclaimer;
