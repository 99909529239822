import classes from './HomePage.module.scss';
import { useTranslation } from 'react-i18next';

const HomePage = () => {
    const mobileWidth = window.innerWidth > 800;
    const { t } = useTranslation('homePage');

    return (
        <div className={classes.container}>
            <img
                alt="homePage_background"
                src={
                    mobileWidth
                        ? require('../../../assets/images/homePageBackground.png')
                        : require('../../../assets/images/homePageBackground_mobile.png')
                }
            />
            <h5 className="sub white">Consultancy / Design / Construction</h5>
            <span className={classes.title}>
                <span className={classes.inline}>
                    <h1 className="orangeMedium">{t('you')}&nbsp;</h1>
                    <h1 className="white">{t('center')}&nbsp;</h1>
                </span>
                <h1 className="white">{t('service')}</h1>
            </span>
        </div>
    );
};

export default HomePage;
