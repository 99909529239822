export const QueryStats = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.45 35.55L2 33.75L11.4 18.75L17.3786 25.75L25.35 12.85L30.8 20.95C30.2667 21.0167 29.75 21.125 29.25 21.275C28.75 21.425 28.25 21.6 27.75 21.8L25.5 18.35L17.8721 30.8L11.8 23.75L4.45 35.55ZM43.85 46L37.15 39.3C36.45 39.8 35.6917 40.1833 34.875 40.45C34.0583 40.7167 33.2167 40.85 32.35 40.85C29.9889 40.85 27.982 40.023 26.3292 38.3691C24.6764 36.7152 23.85 34.7069 23.85 32.3441C23.85 29.9814 24.677 27.975 26.3309 26.325C27.9848 24.675 29.9931 23.85 32.3559 23.85C34.7186 23.85 36.725 24.6764 38.375 26.3292C40.025 27.982 40.85 29.9889 40.85 32.35C40.85 33.2167 40.7083 34.0583 40.425 34.875C40.1417 35.6917 39.7667 36.4741 39.3 37.2224L46 43.85L43.85 46ZM32.343 37.85C33.881 37.85 35.1833 37.319 36.25 36.257C37.3167 35.1951 37.85 33.8951 37.85 32.357C37.85 30.819 37.319 29.5167 36.257 28.45C35.1951 27.3833 33.8951 26.85 32.357 26.85C30.819 26.85 29.5167 27.381 28.45 28.443C27.3833 29.5049 26.85 30.8049 26.85 32.343C26.85 33.881 27.381 35.1833 28.443 36.25C29.5049 37.3167 30.8049 37.85 32.343 37.85ZM36.05 21.45C35.55 21.25 35.0333 21.1167 34.5 21.05C33.9667 20.9833 33.4167 20.9167 32.85 20.85L43.55 4L46 5.8L36.05 21.45Z"
                fill="#2B3939"
            />
        </svg>
    );
};
