import classes from './ContactsBanner.module.scss';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';

const ContactsBanner = () => {
    const mobileWidth = window.innerWidth > 800;
    const { t } = useTranslation('contactsBanner');

    return (
        <div className={classes.container}>
            <img
                loading="lazy"
                alt="background_contact_banner"
                src={
                    mobileWidth
                        ? require('../../../assets/images/background_contacts_banner.png')
                        : require('../../../assets/images/background_contacts_banner_mobile.png')
                }
            />
            <div className={classes.title}>
                <h2 className="white">
                    {t('the')}&nbsp; <span className="orangeMedium">{t('power')}&nbsp;</span>
                    {t('ideas')}&nbsp;
                </h2>
                <h2 className="white">
                    {t('the')}&nbsp; <span className="primaryLight">{t('expertise')}&nbsp;</span>
                    {t('team')}
                </h2>
                <HashLink to="/#contacts" smooth className={classes.mailContainer}>
                    {t('contactUs')}
                </HashLink>
            </div>
        </div>
    );
};

export default ContactsBanner;
