import PresentationComponent from 'components/presentationComponent/PresentationComponent';
import classes from './AboutUs.module.scss';
import AboutUsCarousel from './aboutUsCarousel/AboutUsCarousel';
import { useTranslation } from 'react-i18next';

const AboutUs = () => {
    const { t } = useTranslation('aboutUs');

    return (
        <>
            <div className={classes.container}>
                <PresentationComponent
                    title={t('title')}
                    body={
                        <>
                            <p className="grayMedium">
                                {t('firstP.line_1')}&nbsp;
                                <span className={classes.highlight}>
                                    <b>{t('firstP.line_2_highlight')}</b>
                                </span>
                                , &nbsp;
                                {t('firstP.line_3')}
                            </p>
                            <p className="grayMedium">
                                {t('secondP.line_1')}&nbsp;
                                <span className={classes.highlight}>
                                    <b>{t('secondP.line_2_highlight')}</b>
                                </span>
                                &nbsp;{t('secondP.line_3')}
                            </p>
                            <p className="grayMedium">
                                {t('thirdP.line_1')}&nbsp;
                                <span className={classes.highlight}>
                                    <b>{t('thirdP.line_2_highlight')}</b>
                                </span>
                                &nbsp;
                                {t('thirdP.line_3')}
                            </p>
                        </>
                    }
                />
            </div>
            <AboutUsCarousel />
        </>
    );
};

export default AboutUs;
