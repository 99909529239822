import Navbar from './navbar/Navbar';
import classes from './Home.module.scss';
import HomePage from './homePage/HomePage';
import OurWork from './ourWork/OurWork';
import Services from './services/Services';
import AboutUs from './aboutUs/AboutUs';
import ContactUs from './contacts/ContactUs';
import ContactsBanner from './contactsBanner/ContactsBanner';
import Certifications from './certifications/Certifications';
import OurClients from './ourClients/OurClients';
import Footer from './footer/Footer';
import { useInView } from 'react-intersection-observer';
import { useEffect, useState } from 'react';

const Home = () => {
  const [refHome, inViewHome] = useInView({ threshold: 0.5 });
  const [refOurWork, inViewOurWork] = useInView({ threshold: 0.5 });
  const [refServices, inViewServices] = useInView({ threshold: 0.5 });
  const [refAboutUs, inViewAboutUs] = useInView({ threshold: 0.5 });
  const [refContacts, inViewContacts] = useInView({ threshold: 0.5 });
  const [activeSection, setActiveSection] = useState('#homePage');

  useEffect(() => {
    if (inViewHome) {
      setActiveSection('#homePage');
    } else if (inViewOurWork) {
      setActiveSection('#ourWork');
    } else if (inViewServices) {
      setActiveSection('#services');
    } else if (inViewAboutUs) {
      setActiveSection('#aboutUs');
    } else if (inViewContacts) {
      setActiveSection('#contacts');
    }
  }, [
    inViewAboutUs,
    inViewContacts,
    inViewHome,
    inViewOurWork,
    inViewServices
  ]);

  return (
    <div className={classes.container}>
      <Navbar activeSection={activeSection} />
      <div className={classes.sections}>
        <section ref={refHome} className={classes.section} id="homePage">
          <HomePage />
        </section>
        <section ref={refOurWork} id="ourWork">
          <OurWork />
        </section>
        <section ref={refServices} id="services">
          <Services />
        </section>
        <Certifications />
        <ContactsBanner />
        <section ref={refAboutUs} id="aboutUs">
          <AboutUs />
        </section>
        <OurClients />
        <section ref={refContacts} id="contacts">
          <ContactUs />
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default Home;
