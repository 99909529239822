import PresentationComponent from 'components/presentationComponent/PresentationComponent';
import classes from './OurWork.module.scss';
import { useTranslation } from 'react-i18next';

const OurWork = () => {
    const { t } = useTranslation('whatWeDo');

    return (
        <div className={classes.container}>
            <PresentationComponent
                title={t('title')}
                body={
                    <>
                        <p className="grayMedium">
                            {t('firstP.line_1')}&nbsp;
                            <span className={classes.highlight}>
                                <b>{t('firstP.line_2_highlight')}</b>
                            </span>
                            &nbsp;{t('firstP.line_3')}
                        </p>
                        <p className="grayMedium">{t('secondP')}</p>
                        <p className="grayMedium">
                            {t('thirdP.line_1')}&nbsp;
                            <span className={classes.highlight}>
                                <b>{t('thirdP.line_2_highlight')}</b>
                            </span>
                            &nbsp;{t('thirdP.line_3')}
                        </p>
                    </>
                }
            />
        </div>
    );
};

export default OurWork;
