import { FC } from 'react';
import CertificationItem from './certificationItem/CertificationItem';
import classes from './CertificationList.module.scss';

type CertificationsListProps = FC<{
    isCertificationsListOpen: boolean;
}>;
const CertificationsList: CertificationsListProps = ({ isCertificationsListOpen }) => {
    const items = ['qualification', 'authorization', 'fire', 'appointed', 'site', 'certificate', 'working', 'revit'];

    return (
        <div
            className={` ${classes.container} ${isCertificationsListOpen ? classes.container__open : classes.container__closed} `}
        >
            {items.map(item => (
                <CertificationItem key={item} title={item} />
            ))}
        </div>
    );
};

export default CertificationsList;
